import HeaderUI, { Props } from '@interflora/ui-components/build/components/Header/Header'
import {
  useSearchNBHitsContext,
  useSearchState,
  useSearchUpdate,
} from '@interflora/ui-components/build/components/SearchContext/SearchContext'
import { SiteContext, calculateTotalNBHitsCount } from '@interflora/ui-components/build/utils/common'
import SearchNBHitsStats from '@interflora/ui-components/build/utils/SearchNBHitsStats'
import algoliasearch from 'algoliasearch'
import AnalyticsContext from 'context/AnalyticsContext'
import { signOut } from 'lib/apolloClient'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect } from 'react'
import { Configure, Index, InstantSearch, QueryRuleCustomData } from 'react-instantsearch-dom'

const envPrefix = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX
const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

const Header = (props: Props) => {
  const { shorthand, getConsignmentCount } = useContext(SiteContext)
  const analytics = useContext(AnalyticsContext)
  const { nbHits, setnbHits } = useSearchNBHitsContext()

  const productIndex = `${envPrefix}_product_${shorthand}`
  const categoryIndex = `${envPrefix}_category_${shorthand}`
  const inspirationIndex = `${envPrefix}_inspiration_${shorthand}`

  const index = searchClient.initIndex(productIndex)
  const handleSignOut = async () => {
    await signOut()
    analytics.signOut()
    window.location.assign('/')
  }
  const router = useRouter()
  const setSearchQuery = useSearchUpdate()
  const searchQuery = useSearchState()

  const handleRouteChange = useCallback(() => {
    getConsignmentCount?.refetch()
  }, [getConsignmentCount])

  useEffect(() => {
    document?.addEventListener('visibilitychange', handleRouteChange)
    return () => {
      document?.addEventListener('visibilitychange', handleRouteChange)
    }
  }, [handleRouteChange])

  const handleSuggestedSearchOrPreviousSearch = async (peviousSearchValues: string) => {
    const algoliaSearchResults = await index.search(peviousSearchValues, {
      distinct: true,
      hitsPerPage: 6,
    })
    const match = algoliaSearchResults?.userData?.find((data: any) => Boolean(data.redirect))
    if (match?.redirect) {
      analytics.searchRedirect(match?.redirect)
    }
    const redirectUrl = match?.redirect ? match.redirect : `/search?query=${encodeURIComponent(peviousSearchValues)}`
    router.push(redirectUrl)
  }
  const searchTermAnalytics = (searchInputValue: string) => {
    analytics.searchTerm(searchInputValue)
  }
  const noSearchOverlayResultAnalytics = (message: string) => {
    analytics.searchOverlayNoResults(message)
  }

  return (
    <InstantSearch searchClient={searchClient} indexName={productIndex}>
      <SearchNBHitsStats indexName={productIndex} />
      <QueryRuleCustomData
        transformItems={(items: any) => {
          const match = items.find((data: any) => Boolean(data.redirect))
          if (searchQuery && match && match.redirect) {
            analytics.searchRedirect(match?.redirect)
            router.push(match.redirect)
            setSearchQuery('')
            setnbHits([])
          } else if (searchQuery) {
            const algoliaIndexes = [productIndex, inspirationIndex]
            const totalNbHits = calculateTotalNBHitsCount(nbHits, algoliaIndexes)
            if (totalNbHits) {
              router.push(`/search?query=${encodeURIComponent(searchQuery)}`)
            } else {
              router.push(`/nosearch?query=${encodeURIComponent(searchQuery)}`)
            }
            setSearchQuery('')
            setnbHits([])
          }
          return []
        }}
      >
        {() => null}
      </QueryRuleCustomData>
      <Index indexName={categoryIndex}>
        <SearchNBHitsStats indexName={categoryIndex} />
      </Index>
      <Index indexName={inspirationIndex}>
        <SearchNBHitsStats indexName={inspirationIndex} />
      </Index>
      <Configure hitsPerPage={6} distinct />
      <HeaderUI
        {...props}
        signOut={handleSignOut}
        handleSuggestedSearchOrPreviousSearch={handleSuggestedSearchOrPreviousSearch}
        searchTermAnalytics={searchTermAnalytics}
        noSearchOverlayResultAnalytics={noSearchOverlayResultAnalytics}
        sendMenuDrillDownAnalytics={(clickText) => analytics.menuDrillDownClick(clickText)}
        sendMenuLinkClickAnalytics={(menuText) => analytics.menulinkClick(menuText)}
      />
    </InstantSearch>
  )
}

export default Header
